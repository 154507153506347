import React, {Component} from 'react'
import styled, {keyframes} from 'styled-components'
import {connect} from 'react-redux';

import SEO from '../components/seo'
import Intro from '../components/intro'; 
import { fetchArtists } from '../actions/artists';
import _ from 'lodash';
import { mobile, tablet, desktop } from '../breakpoints';

class IndexPage extends Component {

	constructor(){
		super();

		this.state = {
		 
		}

	}

	componentWillMount(){
		this.props.fetchArtists()
	}

	componentDidMount(){ 
		window.homeSlider();
	}

	componentWillUnmount(){
		window.TweenMax.killAll();
	}



	render() { 

		return (
			<Page>
				<SEO title="Home" keywords={[`radio`, `award winning`, `production`]} />
				
				<Wrapper>
					<Left>
						<Intro/>
					</Left> 
					<Right>
						<section className="carousel">
								<h2 className="title">Adam Beyer</h2>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/adam-beyer.png)', 'zIndex':'1'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/armin-van-burren.png)', 'zIndex':'2'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/chris-liebing.png)', 'zIndex':'3'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/eats-everything.png)', 'zIndex':'4'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/joris-voorn.png)', 'zIndex':'5'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/laptop.png)', 'zIndex':'6'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/nicole-moudaber.png)', 'zIndex':'7'}}></div>
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/paul-oakenfold.png)', 'zIndex':'8'}}></div>
		
								<div className="dummySlide" style={{'backgroundImage': 'url(/artist_carousel/studio.png)', 'zIndex':'10'}}></div>
						</section>
					</Right>
				</Wrapper>
				
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
		artists: state.artists.artists,
		datetime: state.artists.datetime,
	}
}

export default connect(mapStateToProps, { fetchArtists })(IndexPage);

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	height:100%;

	@media only screen and (max-width: ${tablet}) {
		min-height:560px;
		padding-top:60px;
		height:auto;
	}
`

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	
	@media only screen and (max-width: ${tablet}) {
		flex-flow:column-reverse;
		flex-wrap: wrap;
	}

	@media only screen and (min-width: ${tablet}) {
        height:100%;
    }
`

const Container = styled.section`
	padding:20px;
	height:100%;

	
`

const Left = styled(Container)`
	margin-right: auto;
	width:50%;
	background:#c2b0bc;
	overflow:hidden;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
	}
`

const typein = keyframes`
	0%, 50% {
    	width:0%;
	}
	  
	50%, 75% {
		width: 100%;
	}

	75%, 100% {
		0%;
	}
`;

const Right = styled(Container)`
	margin-left: auto;
	width:50%;
	padding:0;
	background:#000;
	position:relative;
	overflow:hidden;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		height:470px	;
	}

	@media only screen and (max-width: ${mobile}) {
		width:100%;
		height:470px !important;
	}

	h2 {
		position: absolute;
		top: 50%;
		zIndex: 200;
		color: #fff;
		width: 100%;
		text-align: center;
		font-size: 4vw;
		
		transform:translateX(-50%) translateY(-50%) !important;
		left:50%;
		margin:0;
		color:#fff !important;
		line-height:1em;

		@media only screen and (max-width: ${mobile}) {
			font-size:10.2vw !important;
		}
		
	}

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
		position: absolute !important;
		top: 0;
		left: 0;
		zIndex: 100;

		@media only screen and (max-width: ${mobile}) {
			height:auto !important;
		}

		img {
			position:absolute !important;
			height:auto;
			object-position: left !important;
		}
	}

	div.gatsby-image-wrapper.active {
		zIndex:20 !important;
	}
	
`