import React from 'react';
import styled from 'styled-components';
import { tablet } from '../breakpoints';

const Intro = () => (
    <Wrapper>
       
        <Inner>
            <h1>The Radio Department</h1>
            <Para>
                We are passionate about making incredible audio.
            </Para>

            <Para>
                We thrive on exploring news ways for our roster of curated talent and brands to engage with tens of millions of people every week.
            </Para>
            
            <Para>
                For 20 years, we have showcased artists, personalities, and events – creating and distributing innovative, award-winning content across FM and digital radio, podcasts, and outside broadcasts that grows audiences and fans globally.
            </Para>
        </Inner>
    </Wrapper>
)


export default Intro;

const Wrapper = styled.div`
    padding:100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width:515px;
    max-width:760px;
    margin:auto;
    height:100%;
    padding-left:85px;

    @media only screen and (max-width: ${tablet}) {
        min-width:inherit;
        padding:20px;
    }
    
    &:before {
        content: 'The Radio Department';
        position: absolute;
        left:0; 
        transform:rotate(90deg);
        font-size:28px;
        left:-108px;
        font-family: 'EuclidFlexMedium';
        letter-spacing:0.03em;
        color:#000;
  

        @media only screen and (max-width: ${tablet}) {
            content: '';
        }
    }
`


const Inner = styled.div`
   display:block;

   h1 {
       display:none;
       font-size: 24px;

       @media only screen and (max-width: ${tablet}) {
            display:none;
       }
   }
`

const Para = styled.p`
    color:#000;
    font-size:22px;
    line-height:1.6em;

    @media only screen and (max-width: ${tablet}) {
        font-size:18px;
	}
`