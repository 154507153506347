import { FETCH_ARTISTS } from './types';
import Artists from '../data/artists';

export function fetchArtists() {

	return {
		type: FETCH_ARTISTS,
		payload: Artists
    };
    
}
